// dummy.scss

// My variable overrides

// use btn-danger for orange buttons
// use btn-success for green buttons
$orange: #e37a11;//#e36c11;// #e37b12; //#e34d12; // #e37b12 // #ff8c00 // #ff3d00
$green:  #80c343; // #32cd32 // #357a38
$danger: $orange;
$primary: $green;
$success: $green;

// $navbar-dark-hover-color: rgba(#222222, .7);
// $navbar-dark-active-color: rgba(#222222, .9);
// $link-color: #ffffff;
// $link-hover-color: #ffffff;

// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Optional
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
// @import "breadcrumb";
// @import "pagination";
// @import "badge";
@import "../node_modules/bootstrap/scss/jumbotron";
// @import "alert";
// @import "progress";
// @import "media";
@import "../node_modules/bootstrap/scss/list-group";
// @import "close";
// @import "toasts";
@import "../node_modules/bootstrap/scss/modal";
// @import "tooltip";
// @import "popover";
@import "../node_modules/bootstrap/scss/carousel";
// @import "spinners";
@import "../node_modules/bootstrap/scss/utilities";
// @import "print";

.jumbotron {
  background-image:
    linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(../images/banner360x180.jpeg);
    background-size: cover;
}

.card-deck .card {
  min-width: 220px;
}

@media (min-width: 990px) and (max-width: 1198px) {
  div.card-deck div.card:last-child {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
}


button.navbar-toggler:focus {
  outline: 1px solid #80c343;// #ff8c00 // #ff3d00
  
}

//.navbar-dark .navbar-nav .nav-link {
//    color: #222222;
//}

.navbar-light .navbar-toggler {
    border-color: #222222;
 }

.testimonials {
  background: #444
  url(../images/banner360x180.jpeg) center center no-repeat fixed;
  background-size: cover;
}

.testimonials-overlay {
  background: rgba(129, 195, 67, 0.85);
  // background: rgba(50, 205, 50, 0.85);
  // background: rgba(53, 122, 56, 0.85); 
}

@media (max-width: 991px) {
  .partner-logos .container .row div.border-right {
    border: 0 !important;
  }
}

.mini-container {
  max-width: 540px;
}

//.btn-danger {
//  color: #fff;
//}

.text-dark {
  color: #212529 !important;
}

//.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
//  color: $green;
//}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    text-decoration: underline;
}

//a.text-dark:focus, a.text-dark:hover {
//    color: #fff !important;
//}

.navbar-light .navbar-nav .nav-link {
    color: #222;
}

.nav-link:hover {
  text-decoration: underline;
}

//.fa-facebook:hover {
//  color: #fff;
// }

.ekko-lightbox .modal-dialog {
  flex: 100%!important;
  margin-left: 0;
  margin-right: 0;
}